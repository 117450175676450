body {
  margin: 0;
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CSS for custom react-data-grid styling
This is to ensure that styling matches with MUI data grid */
.rdg-row {
  background-color: #fff !important;
}
.rdg-header-row {
  background-color: #fff !important;
}
.rdg-cell {
  box-shadow: none !important;
  border-inline-end: none !important;
  padding-left: 12px;
}
.rdg-cell[role="columnheader"]:before {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  height: 20px;
}
.rdg-cell[role="columnheader"] {
  outline: none;
}
.data-grid-with-column-search .rdg-cell[role="columnheader"] {
  height: 90px !important;
}
.column-search-text-input label.MuiFormLabel-root {
  margin-top: -9px;
}
.rdg-caret {
  display: none;
}
.rdg-group-row .rdg-group-cell-content:before {
  content: "";
  display: inline-block;
  border-right: 2px solid #00000080;
  border-bottom: 2px solid #00000080;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  margin-right: 10px;
}
.rdg-group-row[aria-expanded="true"] .rdg-group-cell-content:before {
  content: "";
  display: inline-block;
  border-right: 2px solid #00000080;
  border-bottom: 2px solid #00000080;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  margin-right: 6px;
  margin-bottom: 2px;
  margin-left: 4px;
}
.sites-data-grid .rdg-row:hover {
  background-color: #eaedef !important;
}
/* End of CSS for custom react-data-grid styling */
